@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.eot); /* For IE6-8 */
    src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2'),
    url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff) format('woff'),
    url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.ttf) format('truetype');
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;

    /* Support for IE. */
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';

    /* Support for all WebKit browsers. */
    /* -webkit-font-smoothing: antialiased; */

    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    /* -moz-osx-font-smoothing: grayscale; */
}

/* Basic Resets */
a{text-decoration:none; color:inherit; cursor:pointer;}
button{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
figure{margin:0;}
input::-moz-focus-inner {border:0; padding:0; margin:0;}
ul, ol, dd{margin:0; padding:0; list-style:none;}
/*h1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}*/
p{margin:0;}
cite {font-style:normal;}
fieldset{border-width:0; padding:0; margin:0;}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.ag-react-container {
    height: 100%;
    width: 100%;
}

.ag-cell, .ag-header-cell {
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: unset;
}

.ag-header-cell, .ag-cell:not(.ag-cell-range-selected) {
    border: 0px !important;
}

.ag-header-cell-resize::after {
    width: 0px !important;
}

.ag-header-group-cell {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.ag-header {
    border-bottom: 0px !important;
}

.ag-center-cols-container, .ag-center-cols-clipper {
    min-height: 0px !important;
}

.ag-layout-normal {
    height: 100% !important;
}

.ag-range-handle {
    background-color: unset !important;
}

.ag-cell-inline-editing {
    border: unset !important;
}

.ag-theme-alpine .ag-root-wrapper {
    border: none;
}

.ag-theme-alpine .ag-header {
    background-color: #ffffff;
}

.ag-theme-alpine .ag-root-wrapper {
    border: none;
}

.ag-theme-alpine .ag-header {
    background-color: unset;
}

.ag-header-group-cell-no-group {
    border: unset;
}

.ag-theme-alpine .ag-pinned-left-header {
    border-right: unset;
}

.ag-pinned-left-cols-container .ag-react-container:first-of-type span, .ag-header-group-cell-with-group {
    border-left: 1px inset;
}

.ag-popup-child {
    z-index: 1500;
}

.ag-header-group-cell-with-group + .ag-header-group-cell-no-group:not(:last-child) {
    border-right: 1px inset rgb(109, 110, 113);
}

/*h1 { font-size: 2.5rem; }*/
/*h2 { font-size: 2rem; }*/
/*h3 { font-size: 1.5rem; }*/
/*h4 { font-size: 1rem; }*/
